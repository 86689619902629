import * as React from 'react'
import {Link} from 'gatsby'
import i18next from "i18next";
import {StaticImage} from 'gatsby-plugin-image';

import * as headerStyles from './header.module.scss';

const Header = () => {
    return (
        <header className={headerStyles.header}>
            <nav className={headerStyles.navbar}>
                <Link to="/">
                    <StaticImage height={70} alt="logo" src="../../images/Prajanya-LOGO.png"/>
                </Link>
                <ul className='list'>
                    {
                        i18next.t("menu.menus", {returnObjects: true})
                            ?.map((value, index) => {
                                return (
                                    <li key={index + value.title} className='list__item'>
                                        <Link
                                            style={{textDecoration: 'none', color: 'black'}}
                                            to={value.link}>
                                            {value.title}
                                        </Link>
                                    </li>)
                            })
                    }

                </ul>
            </nav>
        </header>
    )
}
export default Header;
