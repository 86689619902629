import * as React from 'react'
import sprite from '../../../assets/sprites.svg';

const PjIcon = ({width, height, fill, stroke, iconName }) => {
    return (
        <svg width={width} height={height} fill={fill} stroke={stroke} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <use href={`${sprite}#${iconName}`}></use>
        </svg>
    );

};

export default PjIcon;


