import * as React from 'react'
import Footer from './footer/footer';
import Header from './header/header';

const Layout = ({ pageTitle, children }) => {
    return (
        <div>
            <title>Prajanya | {pageTitle}</title>
            <Header/>
            <main>
                {children}
            </main>
            <Footer/>
        </div>
    )
}
export default Layout;