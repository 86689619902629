import {Link} from 'gatsby';
import * as React from 'react';
import PjIcon from '../../components/icons/svg-icon';
import i18next from "i18next";

const Footer = () => {
    return (
        <div className='bg-primary font-s2r p-6r fg-light'>
            <div className="row border-b-1p">
                <div className="col-1-of-3">
                    <h3 className="font-w1 mb-1r">{i18next.t("footer.links.title")}</h3>
                    <ul className='list'>
                        {
                            i18next.t('footer.links.links', { returnObjects: true })
                                ?.map((link, index) => {

                                    return (
                                        <li key={`link-${index}`} className='list__item list__item--light list__item--block'>
                                            <Link
                                                style={{textDecoration: 'none', color: 'white'}}
                                                to={link.link}>
                                                {link.title}
                                            </Link>
                                        </li>
                                    )
                                })
                        }
                    </ul>
                </div>
                <div className="col-1-of-3">
                    <h3 className="font-w1 mb-1r">{i18next.t('footer.company.name')}</h3>
                    <address className="font-s1-6r">
                        <p className="mb-1r">
                            <PjIcon
                                stroke="white"
                                iconName="location"
                                width='1.3em'
                                height='1.3em'>

                            </PjIcon>
                            <span> {i18next.t('footer.company.location')}</span>
                        </p>
                        <p className="mb-1r">
                            <PjIcon
                                stroke="white"
                                iconName="phone"
                                width='1.3em'
                                height='1.3em'>
                            </PjIcon>
                             {i18next.t('footer.company.contact')}
                        </p>
                    </address>
                </div>

            </div>
            <div className="row font-s1-3r">
                <p>{i18next.t('footer.copyrights')}</p>
            </div>
        </div>

    );
}

export default Footer;
