exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-home-contact-us-contact-us-jsx": () => import("./../../../src/pages/home/contact-us/contact-us.jsx" /* webpackChunkName: "component---src-pages-home-contact-us-contact-us-jsx" */),
  "component---src-pages-home-expertise-expertise-jsx": () => import("./../../../src/pages/home/expertise/expertise.jsx" /* webpackChunkName: "component---src-pages-home-expertise-expertise-jsx" */),
  "component---src-pages-home-hero-section-hero-jsx": () => import("./../../../src/pages/home/hero-section/hero.jsx" /* webpackChunkName: "component---src-pages-home-hero-section-hero-jsx" */),
  "component---src-pages-home-jsx": () => import("./../../../src/pages/home.jsx" /* webpackChunkName: "component---src-pages-home-jsx" */),
  "component---src-pages-home-our-projects-section-our-projects-jsx": () => import("./../../../src/pages/home/our-projects-section/our-projects.jsx" /* webpackChunkName: "component---src-pages-home-our-projects-section-our-projects-jsx" */),
  "component---src-pages-home-our-services-section-services-jsx": () => import("./../../../src/pages/home/our-services-section/services.jsx" /* webpackChunkName: "component---src-pages-home-our-services-section-services-jsx" */),
  "component---src-pages-home-technologies-used-technologies-used-jsx": () => import("./../../../src/pages/home/technologies-used/technologies-used.jsx" /* webpackChunkName: "component---src-pages-home-technologies-used-technologies-used-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-our-team-jsx": () => import("./../../../src/pages/our-team.jsx" /* webpackChunkName: "component---src-pages-our-team-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */)
}

